// Site header
.site-header {
    min-height: 56px;
    background-color: $background-secondary;
    padding: 8px 0;
    border-bottom: 1px solid $borders-light;
    // Positioning context for the mobile navigation icon
    position: relative;

    @include media-query($on-palm) {
        padding: 0;
    }
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $theme-main;
    }
}

// Navigation styles
.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-secondary;
        line-height: $base-line-height;
        margin-left: 20px;
    }

    @include media-query($on-palm) {
        position: absolute;
        z-index: 2;
        top: 17px;
        right: $spacing-unit / 2;
        background-color: $background-secondary;
        border: 1px solid $borders-light;
        border-radius: 5px;
        text-align: right;
        @include media-query($on-palm) {
            top: 9px;
        }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $theme-main;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

// Site footer
.site-footer {
    background-color: $background-secondary;
    border-top: 1px solid $borders-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $text-secondary;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
    box-sizing: border-box;
}

.footer-col-1 {
    width: 35%;
}

.footer-col-2 {
    width: 20%;
}

.footer-col-3 {
    width: 45%;
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: 50%;
    }

    .footer-col-3 {
        width: 100%;
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: 100%;
    }
}

// Page content
.page-content {
    padding: $spacing-unit 0;
    @include media-query($on-palm) {
        padding: $px-spacing-unit * 3 0;
    }
}

// Homepage list of posts.
.hero-row {
    width: 100%;
    display: table;

    @include media-query($on-palm) {
        display: block;
    }
}

.hero-row-image {
    width: 30%;
    min-width: 125px;
    display: table-cell;
    vertical-align: middle;

    @include media-query($on-palm) {
        display: none;
        width: 125px;
        margin: auto;
        margin-bottom: 8px;
    }
}

.hero-row-bio {
    width: 70%;
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;

    @include media-query($on-palm) {
        display: block;
        width: 100%;
        padding: 0;
    }

    h1 {
        margin: 0;
        font-size: 2.4em;
        line-height: 1;

        span {
            color: $theme-main;
        }

        @include media-query($on-palm) {
            font-size: 2.2em;
        }
    }

    h3 {
        margin: 0;
        font-size: 1.8em;
        @include media-query($on-palm) {
            font-size: 1.5em;
        }
    }
}

.hero-bio {
    margin-bottom: 0;
    font-size: 1.1em;
    @include media-query($on-palm) {
        font-size: 1em;
    }
}

.post-list-content {
    padding-top: $spacing-unit / 2;
    padding-bottom: $spacing-unit / 2;
    border-bottom: 1px solid $borders-light;

    &:first-child {
        border-top: 1px solid $borders-light;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    h2 {
        margin: 0;
        line-height: 1.1;

        .post-link {
            display: block;
            font-size: 30px;
            &:hover {
                text-decoration: none;
            }
            &:visited {
                color: $theme-main;
            }
        }
    }

    p {
        font-size: 1.1em;
        color: $text-secondary;
        line-height: $base-line-height;
        margin-bottom: 10px;
        
        @include media-query($on-palm) {
            font-size: 1em;
            line-height: 24px;
        }
    }

    .read-more {
        border-left: none;
    }
}

// about page
ul li p {
    margin-bottom: 8px;
}

// contact page
.require {
    color: $theme-error;
    display: none;
}

label {
    margin-top: 16px;
    display: block;
    width: 80%;

    @include media-query($on-palm) {
        width: 100%;
    }

    span {
        font-size: 1.1em;
        display: inline-block;
        margin-bottom: 4px;
    }
}

input[type=text] {
    width: 100%;
    display: block;
    padding: 8px;
    font-size: 1.1em;
    box-sizing: border-box;
    border: 1px solid $borders-light;
    border-radius: 0px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

textarea {
    width: 100%;
    display: block;
    padding: 8px;
    font-size: 1.1em;
    box-sizing: border-box;
    font-family: inherit;
    border: 1px solid $borders-light;
    border-radius: 0px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

input[type=submit] {
    margin-top: 16px;
    color: $text-secondary;
    padding: 14px 20px;
    font-size: 1.3em;
    border: none;
    cursor: pointer;
    background-color: $background-secondary;
    border-radius: 0px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    &:hover {
        background-color: $theme-main;
        color: $text-light;
    }
}

// Meta under post titles
.post-meta {
    font-size: $small-font-size;
    color: $borders;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 400;
}

.meta-border {
    display: block;
    border-bottom: 1px solid $borders-light;
    margin-bottom: $spacing-unit / 2;
    padding-bottom: $spacing-unit / 2;
}

// Tags used under posts and on projects.
.post-footer-links {
    margin-top: 16px;
}

.post-tweet {
    display: block;
    text-align: center;
    margin-top: 16px;
    border: 1px solid $borders-light;
    padding: 16px 0;
    background-color: $background-secondary;
    font-size: 1.1em;

    @include media-query($on-palm) {
        font-size: 1em;
    }
}

.category-tag-list {
    margin: 8px 0 0 0;
}

.category-tag {
    display: inline-block;
    margin: 8px 4px 0 0;
    padding: 4px 8px;
    border: 2px solid $borders-light;
    border-radius: 10px;
    color: $text-secondary;
    background-color: $background-secondary;
}

// Posts
.post-header {
    margin-bottom: $spacing-unit;
}

// Title above posts
.post-title {
    position: relative;
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
    margin-top: 0.5em;
    color: $theme-main;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        bottom: -0.2em;
        width: 1em;
        margin-bottom: -2px;
        border-bottom: 4px solid $theme-dark;
    }

    @include media-query($on-laptop) {
        font-size: 36px;
    }

    @include media-query($on-palm) {
        margin-top: 0;
    }
}

.post-content {
    margin-bottom: $px-spacing-unit * 2;

    p {
        font-size: $base-post-font-size;
        line-height: $base-line-height;
        color: $text-secondary;
        
        @include media-query($on-palm) {
            font-size: 1em;
            line-height: 24px;
        }
    }

    h1 {
        font-size: 32px;
        margin-bottom: 5px;
        line-height: 1.2;

        @include media-query($on-palm) {
            font-size: 28px;
        }
    }

    h2 {
        font-size: 29px;
        margin-bottom: 5px;

        @include media-query($on-palm) {
            font-size: 25px;
        }
    }

    h3 {
        font-size: 26px;
        margin-bottom: 5px;

        @include media-query($on-palm) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;
        margin-bottom: 5px;

        @include media-query($on-palm) {
            font-size: 18px;
        }
    }

    li {
        color: $text-secondary;
    }
}
